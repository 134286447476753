<template>
    <v-stepper v-model="stepNo">
        <v-stepper-header>
            <template v-for="(title, index) in titles">
                <v-stepper-step :complete="stepNo > index + 1" :step="index + 1" :key="'step'+index">{{title}}</v-stepper-step>
                <v-divider v-if="index !== titles.length - 1" :key="'devider'+index"></v-divider>
            </template>
        </v-stepper-header>
        <v-stepper-items>
            <v-stepper-content step="1">
                <QRReader :camera="camera" @query="onQuery"></QRReader>
            </v-stepper-content>
            <v-stepper-content step="2" class="pt-1" id="pageTwo">
                <v-layout justify-center>
                    <v-flex xs12 sm10 md8 lg8>
                        <door-selector
                            :enable="stepNo === 2"
                            :box-id="boxId"
                            :door-id.sync="doorId"
                            @select="selectDoorToOnePlan"
                            @back="preStep"
                        ></door-selector>
                    </v-flex>
                </v-layout>
            </v-stepper-content>
            <v-stepper-content step="3">
                <v-layout justify-center>
                    <v-flex xs12 sm8 md6>
                        <v-card flat>
                            <v-card-text class="subheading">
                                <ul v-for="(item, index) in detail" :key=index class="nobull">
                                    <li :class="{ bold: (item.title === $t('putIn.cell-number')) }">{{ item.title }}&nbsp;&nbsp;{{ item.content }}</li>
                                </ul>
                            </v-card-text>
                        </v-card>
                        <v-alert :value="true" type="warning" class="subheading">{{ $t('putIn.close-door') }}</v-alert>
                    </v-flex>
                </v-layout>
                <v-layout justify-center>
                    <v-btn large block class="ma-1" color="primary" @click="finish" data-cy="finishBtn">{{ $t('putIn.done') }}</v-btn>
                </v-layout>
            </v-stepper-content>
            <v-dialog persistent v-model="emptyDialog" width="250">
                <v-card class="text-xs-center">
                    <v-card-text class="title">
                        {{ dialogMessage }}
                    </v-card-text>
                    <v-card-actions class="justify-center">
                        <v-btn block class="subheading" color="primary" @click="leave()">{{ $t('putIn.close') }}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-stepper-items>
    </v-stepper>
</template>

<script>
import axios from 'axios'
import store, { HostUrl } from '@/store'
import BoxIdWatcherMixin from '@/mixins/BoxIdWatcher'
import ControlResultWatcher from '@/mixins/ControlResultWatcher'
import PutinMixin, { TransTypes } from '@/mixins/PutinMixin'
import { getAxiosConfig } from '@/utils/AuthService'
import i18n from '@/i18n'
import { HOMETYPES } from '@/utils/utils'

export default {
    name: 'docsInBox',
    mixins: [ BoxIdWatcherMixin, ControlResultWatcher, PutinMixin ],
    data() {
        store.commit('setPageHome', HOMETYPES.TRAVELDOCS)
        return {
            titles: [i18n.t('putIn.scan-code'), i18n.t('putIn.select-cell'), i18n.t('putIn.put-items')],
            enablePayment: false,
            payedAmount: 0,
            transtype: TransTypes.DocsClient,
            sendTimes: 0,
            putinTime: null,
            putinUnixtime: null,
            targetPlan: null,
            emptyDialog: false,
            message: i18n.t('putIn.line-pay-transaction'),
            dialogMessage: i18n.t('putIn.cabinet-unavailable')
        }
    },
    computed: {
        detail() {
            return [
                { title: i18n.t('putIn.cabinet-number'), content: this.boxId },
                { title: i18n.t('putIn.cell-number'), content: this.doorId },
                { title: i18n.t('putIn.transaction-number'), content: this.trackNo },
                { title: i18n.t('putIn.storage-time'), content: this.putinTime }
            ]
        }
    },
    methods: {
        setStorehome(){
            store.commit('setPageHome', HOMETYPES.TRAVELDOCS);
        },
        selectDoorToOnePlan(door, rate){
            this.selectDoor(door, rate);
            //alert(`${door.id} ${JSON.stringify(rate)} ${door.is_available}`)
            if (door == null) return
            if (door.is_available) this.callPutinAPI()
            //if (this.plans.length === 1) {
            //    this.targetPlan = this.plans[0];
            //    this.nextStep()
            //}
        },
        callPutinAPI() {
            let payload = {
                door_id: this.doorId,
                client_id: store.getters.clientId,
                trans_type: TransTypes.DocsClient
            }
            let url = HostUrl + `/api/box/${this.boxId}/`+HOMETYPES.TRAVELDOCS+`/putin`
            this.showDialog(i18n.t('putIn.trading'), i18n.t('putIn.transaction-failed'), 30000)
            let self = this;
            console.log('url::' + url)
            console.log('payload', payload)
            axios.post(url, payload, getAxiosConfig())
                .then(function(response) {
                    console.log('response', response)
                    if (response.data.code === 0) {
                        self.trackNo = response.data.track_no
                        self.payedAmount = response.data.amount
                        self.putinTime = response.data.putin_time
                        self.putinUnixtime = response.data.putin_unixtime
                    } else {
                        if (response.data.message === 'cabinet unavailable') {
                            self.emptyDialog = true
                            self.closeDialog()
                            self.$router.push(`/${i18n.locale}/traveldocshome`);
                        } else {
                            store.commit('setSnackBar', { message: i18n.t('putIn.transaction-failed'), color: 'error' })
                            self.closeDialog()
                        }
                    }
                })
                .catch(function() {
                    store.commit('setSnackBar', { message: i18n.t('putIn.transaction-failed'), color: 'error' })
                    self.closeDialog()
                })
        },
        conti() {
            this.showDialog(i18n.t('putIn.data-loading'), i18n.t('putIn.loading-failed'))
            setTimeout(() => {
                store.commit('setElectronicMoneyZeroMark', null)
                this.doorId = 0
                this.doorInfo = null
                this.setStepNo(2)
                this.closeDialog()
            }, 3000);
        },
        finish() {
            this.setStorehome()
            this.$router.push(`/${i18n.locale}/traveldocshome`);
            store.commit('setElectronicMoneyZeroMark', null)
            this.init()
        },
        leave() {
            this.setStorehome()
            this.init()
            this.$router.push(`/${i18n.locale}/traveldocshome`)
        }
    },
    activated() {

        // setTimeout(function(){
        //     store.commit('setTargetBoxId', '099001')
        // }, 1000)
    }
}
</script>

<style scoped>
    .nobull {
        list-style-type: none;
        padding-left: 0px;
    }
    .bold {
        font-weight: bold;
        font-size: 1.25rem;
    }
</style>
